import React from 'react'
import Card from 'react-bootstrap/Card';


import surgery from './img/general-sergery.jpg'
import lapro from './img/lapro.jpg'
import neuro from './img/neuro.jpg'
import gyneo from './img/gyneo.jpg'
import pedia from './img/pedia.jpg'
import ortho from './img/ortho.jpg'
import ent from './img/ent.jpg'
import phychrist from './img/phychrist.jpg'

const Treatment = () => {
    return (
        <>

            <hr />
            <center><h1>PARUL HOSPITAL TREATMENT</h1></center>
            <br />

            <div className='tretment'>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={surgery} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>General Surgery</Card.Title>
                            <Card.Text>
                                The branch of surgery that covers the main areas of surgical treatment. General surgeons treat diseases of the abdomen, breast, head and neck, blood vessels, and digestive tract.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={lapro} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title> Laparoscopic Surgery </Card.Title>
                            <Card.Text>
                                Laparoscopic surgery is a surgical technique in which short, narrow tubes (trochars) are inserted into the abdomen through small (less than one centimeter) incisions.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={neuro} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>Neurology</Card.Title>
                            <Card.Text>
                                Neurology, medical specialty concerned with the nervous system and its functional or organic disorders. Neurologists diagnose and treat diseases and disorders of the brain, spinal cord, and nerves.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={gyneo} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>Gynaecology & Obstetrics</Card.Title>
                            <Card.Text>
                                Gynecology is the care of a woman's reproductive organs and health. Obstetrics involves the treatment of pregnant women, including the delivery of babies.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

            </div>


            <div className='tretment'>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={pedia} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>Pediatrics</Card.Title>
                            <Card.Text>
                                Pediatrics (also spelled paediatrics or pædiatrics) is the branch of medicine that involves the medical care of infants, children, adolescents, and young adults.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={ortho} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title> Orthopedics</Card.Title>
                            <Card.Text>
                                orthopedics, medical specialty concerned with the preservation and restoration of function of the skeletal system and its associated structures
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={ent} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>EAR NOSE THROAT</Card.Title>
                            <Card.Text>
                                Otorhinolaryngology is a surgical subspecialty within medicine that deals with the surgical and medical management of conditions of the head and neck.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className='tret'>
                    <Card style={{ width: '100%' }}>
                        <Card.Img variant="top" src={phychrist} alt="General Surgery" />
                        <Card.Body>
                            <Card.Title>Psychiatrist</Card.Title>
                            <Card.Text>
                                Psychiatry is the branch of medicine focused on the diagnosis, treatment and prevention of mental, emotional and behavioral disorders.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

            </div>



        </>
    )
}

export default Treatment