import React from 'react'
import Footer from './Footer'
import about from './img/about.jpg';

const About2 = () => {
  return (
    <>
    <br/>

    <center><h2>About</h2></center>

      <div className='about'>
        <div className='about2'>
          <h1>PARUL HOSPITAL</h1>
          <p>Parul Hospital is proud to be recognized as the best multi-specialty hospital in Lucknow, offering world-class medical services with a patient-centered approach. Our mission is to provide comprehensive healthcare services of the highest quality, ensuring the well-being and satisfaction of our patients.</p>

          <h2>Our Vision</h2>
          <p>At Parul Hospital, we envision a healthier community where every individual has access to the best medical care. We strive to be the leading healthcare provider in the region, known for our excellence in medical services, innovative treatments, and compassionate care.</p>




        </div>

        <div className='about3'>
          <img src={about} />
        </div>
      </div>



      <Footer />
    </>
  )
}

export default About2