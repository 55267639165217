import React, { useState } from 'react';



const Head = () => {
 

    return (
        <div className='head'>
            <div className='head2'>
                <a href='Tel:05224625501'>05224625501</a>
            </div>
            
            <div className='head2'>
                <p>parulhospitallko@gmail.com</p>
            </div>
            <div className='head3'>
                <p>Sec-F Jankipuram Near Engineering College Chauraha Lucknow 226021 </p>
            </div>
           
        </div>
    );
};

export default Head;
