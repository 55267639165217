
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

import Head from './component/Head.js';
import Navbar from './component/Navbar';
import Layout from  './component/Layout.js';


import About2 from    './component/About2.js';
import Gallery from './component/Gallery.js';
import Contact from './component/Contact.js';
import Register from './component/Register';

function App() {
 

  return (
    <div className='main'>
      <Router>
      <Head  />
      <Navbar />
      <Routes >
        <Route path="*" element={<Layout  />} />


        <Route path="/About2" element={<About2  />} />
        <Route path="/Gallery" element={<Gallery  />} />
        <Route path="/Contact" element={<Contact  />} />
        <Route path="/Register" element={<Register  />} />

      </Routes>
    </Router>
    </div>
  );
}

export default App;
