import React from 'react';
import Footer from './Footer';


const Contact = () => {
  return (
    <>
      <div className="contact-container">
        <h2>Contact</h2>
        <div className="map-container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11619.346596659903!2d80.94639390907568!3d26.91206278452856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3999576f9f22f48d%3A0x508030755a64b0de!2sParul%20Hospital!5e0!3m2!1sen!2sin!4v1719303150329!5m2!1sen!2sin"   ></iframe>
        </div>
        <div className="contact-info">
          <p>Sec-F Jankipuram Near Engineering College Chauraha Lucknow 226021</p>
          <p>Email: <a href="mailto: parulhospitallko@gmail.com"> parulhospitallko@gmail.com</a></p>
          <p>Phone: <a href="tel:05224625501">05224625501</a></p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
