import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './img/logo2.png'

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <div className='navigation'>
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand" >
                            <img src={logo} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/About2" className="nav-link" onClick={handleNavItemClick}>About</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Treatments</Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><Link to="/general-surgery" className="dropdown-item" onClick={handleNavItemClick}>General Surgery</Link></li>
                                        <li><Link to="/laparoscopic-surgery" className="dropdown-item" onClick={handleNavItemClick}>Laparoscopic Surgery</Link></li>
                                        <li><Link to="/neurology" className="dropdown-item" onClick={handleNavItemClick}>Neurology</Link></li>
                                        <li><Link to="/gynaecology-obstetrics" className="dropdown-item" onClick={handleNavItemClick}>Gynaecology & Obstetrics</Link></li>
                                        <li><Link to="/pediatrics" className="dropdown-item" onClick={handleNavItemClick}>Pediatrics</Link></li>
                                        <li><Link to="/orthopedics" className="dropdown-item" onClick={handleNavItemClick}>Orthopedics</Link></li>
                                        <li><Link to="/ear-nose-throat" className="dropdown-item" onClick={handleNavItemClick}>EAR NOSE THROAT</Link></li>
                                    </ul>

                                </li>



                                <li className="nav-item">
                                    <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Contact" className="nav-link" onClick={handleNavItemClick}>Contact</Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/Register" className="nav-link" onClick={handleNavItemClick}>Appointment</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
        </>
    );
}

export default Navbar;
